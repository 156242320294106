.Beats {
  background: url("../../assets/img/beats-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  /* background: linear-gradient(
    128deg,
    rgba(74, 74, 74, 1) 17%,
    rgba(0, 0, 0, 1) 100%
  ); */
  padding-bottom: 10vh;
  
}


.Beats_heading {
  font-family: "Bungee-Regular";
  display: flex;
  align-items: center;
  font-size: 6vw;
  line-height: 8rem;
  color: white;
  padding: 2%;
}

.Beats_playable {
  width: 65%;

  margin: 0 auto;
}

.Beats_info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Beats_info h3,
.album_artwork_end h3 {
  color: white;
  font-family: "Raleway";
  margin: 0.6rem 0;
}

.Beats_info h4,
.album_artwork_end h4 {
  color: darkgray;
  font-family: "Raleway";
  margin: 0rem 0;
  margin-bottom: 40px;
}

.album_ctn {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.album_artwork {
  width: 300px;
  height: 300px;
  cursor: pointer;
  position: relative;
  vertical-align: middle;
  border-radius: 50%;
}

.album_artwork_end {
  width: 280px;
  height: 300px;
  position: relative;
  background: linear-gradient(96.61deg, #4b21b0 2.05%, #1f006a 96.83%);
  color: white;
  font-family: "Bungee-Regular";
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-size: 1.3rem;
  line-height: 2rem;
  margin-bottom: 0.3rem;
  border-radius: 50%;
  padding: 0 10px;
}

.Beats_info a {
  text-decoration: none;
  color: white;
}

.Beats_info a:hover,
.Beats_info a:active {
  color: rgb(182, 10, 182);
}

.Beats_player {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Beats_player h5 {
  position: absolute;
  z-index: 3;
  color: red;
}

.play {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
}

.icon {
  fill: white;
}

/* .album_ctn:hover .album_artwork {
  filter: blur(0.3rem);
} */

/* .album_ctn:hover .play {
  cursor: pointer;
  animation: fadein 1.6s;
} */

.Beats h2 {
  font-family: "Bungee-Regular";
  font-size: 3rem;
  color: rgb(119, 119, 119);
  display: flex;
  align-items: center;
  width: 80%;
  margin: 0;
}

.Beats_producers {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.producers_list {
  width: 65%;
}

.producer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: "Raleway";
}

.producer img {
  border-radius: 50%;
  box-shadow: 0px 0px 7px 7px rgba(87, 87, 87, 0.452);
  margin-top: 10%;
}

audio {
  position: absolute;
  z-index: 2;
  width: 60vw;
  margin-top: 30px;
}

@media (max-width: 1022px) {
  .Beats_heading {
    justify-content: center;
    align-items: center;
    padding-left: 2%;
    padding-top: 6%;
    padding-bottom: 10%;
    line-height: 4vh;
    font-size: 3.3rem;
  }
  audio {
    width: 90%;
  }
  .Beats_playable {
    width: 100%;
  }
  .album_artwork {
    width: 200px;
    height: 200px;
  }
  .album_artwork_end {
    width: 190px;
    height: 200px;
    font-size: 0.9rem;
    padding: 0 5px;
  }

  .Beats_info h3 {
    font-size: 0.8rem;
    margin: 0.3rem 0;
  }
  .Beats_info h4 {
    font-size: 0.7rem;
    margin-bottom: 20px;
  }
  .Beats h2 {
    font-size: 1rem;
    margin: 1rem;
  }
  .producers_list {
    width: 90%;
  }
  .producer {
    font-size: 0.75rem;
  }
  .producer img {
    width: 70%;
    height: auto;
  }
  .Beats_other_info {
    font-size: 0.4rem;
    text-align: center;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
