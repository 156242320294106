.player {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  background-color: transparent;

  .song {
    user-select: none;
    margin: 0 20px;
    .song__title {
      text-align: left;
      margin: 0;
      color: white;
      font-weight: normal;
      font-size: 3.5em;

      &:hover {
        color: greenyellow;
      }
    }

    .song__artist {
      margin: 0;
      color: white;
      font-family: "Rock Salt", cursive;
      font-weight: normal;
      font-size: 1em;

      &:hover {
        color: purple;
        cursor: pointer;
      }
    }
  }

  .controls {
    flex-grow: 1;
    margin: 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .player__button {
    width: fit-content;
    margin-bottom: 15px;
    background-color: transparent;
    border: none;

    &:focus {
      outline: none;
    }
    &:hover {
      cursor: pointer;
      svg {
        color: white;
      }
    }

    svg {
      font-size: 4em;
      color: white;
    }
  }

  .bar {
    user-select: none;
    width: 100%;
    display: flex;
    align-items: center;

    .bar__time {
      color: white;
      font-size: 16px;
    }

    .bar__progress {
      flex: 1;
      border-radius: 5px;
      margin: 0 20px;
      height: 8px; //4
      display: flex;
      align-items: center;
      cursor: pointer;

      .bar__progress__knob {
        position: relative;
        height: 25px;
        width: 25px;
        border: 1.5px solid white;
        border-radius: 50%;
        background-color: white;
      }
    }
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;

    .controls {
      width: 100%;
      margin-top: 20px;
    }

    .bar {
      width: 90%;
      .bar__progress {
        height: 4px;
        .bar__progress__knob {
          height: 15px;
          width: 15px;
        }
      }
    }
  }

  @media screen and (max-width: 500px) {
    .song {
      .song__title {
        font-size: 2.5em;
      }

      .song__artist {
        font-size: 0.8em;
      }
    }
  }
}

.loader,
.loader:before,
.loader:after {
  border-radius: 50%;
}
.loader {
  color: #6f00dd;
  font-size: 11px;
  text-indent: -99999em;
  margin: 55px auto;
  position: relative;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.loader:before,
.loader:after {
  position: absolute;
  content: "";
}
.loader:before {
  width: 5.2em;
  height: 10.2em;
  background: transparent;
  border-radius: 10.2em 0 0 10.2em;
  top: -0.1em;
  left: -0.1em;
  -webkit-transform-origin: 5.1em 5.1em;
  transform-origin: 5.1em 5.1em;
  -webkit-animation: load2 2s infinite ease 1.5s;
  animation: load2 2s infinite ease 1.5s;
}
.loader:after {
  width: 5.2em;
  height: 10.2em;
  background: transparent;
  border-radius: 0 10.2em 10.2em 0;
  top: -0.1em;
  left: 4.9em;
  -webkit-transform-origin: 0.1em 5.1em;
  transform-origin: 0.1em 5.1em;
  -webkit-animation: load2 2s infinite ease;
  animation: load2 2s infinite ease;
}
@-webkit-keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load2 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
