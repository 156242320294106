.Producers {
  background: url(../../assets/img/producers-bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 3rem;
}

.Producers_title {
  align-self: flex-start;
  color: white;
  font-family: "Bungee-Regular";
  padding: 2%;
  margin: 0;
  font-size: 6vw;
  display: flex;
  align-items: center;
  line-height: 8rem;
}

.Producers_body {
  width: 100%;
  display: flex;
  justify-content: center;
}

.prod_box {
  display: flex;
  color: white;
  flex-direction: column;
  background: rgba(126, 126, 126, 0.65);
  padding: 25px;
  width: 30rem;
  height: 250px;
  margin-top: 7rem;
  border-radius: 15px;
}

.prod_box h3 {
  font-family: "Bungee-Regular";
  margin: 0;
  font-size: 2.5rem;
  margin: 0;
  line-height: 3rem;
}

.prod_box h6 {
  font-family: "Raleway";
  margin: 0;
  font-size: 1.2rem;
  margin: 0.7rem 0;
  color: lightgray;
}

.prod_box p {
  margin: 0rem 0;
  line-height: 1.3rem;
}

.prod_portal_btn {
  text-decoration: none;
  color: white;
  background: linear-gradient(113.75deg, #4b21b0 0%, #1f006a 100%);
  border-radius: 11px;
  width: 300px;
  height: 3rem;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.prod_portal_circle_ctn {
  /* position: relative; */
  display: flex;
  /* width: 10%; */
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin-left: -1px;
}

.prod_portal_outer_circ {
  background: transparent;
  border-radius: 100%;
  border: 2px solid white;
  width: 18px;
  height: 18px;
  /* position: relative; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: auto;
}
.prod_portal_inner_circ {
  /* position: absolute; */
  background: transparent;
  border-radius: 100%;
  border: 2px solid white;
  height: 8px;
  width: 8px;
}

.prod_portal_btn:hover {
  opacity: 0.5;
}
.MOBILE_prod_btn {
  display: none;
}

@media (max-width: 1022px) {
  .Producers {
    padding-bottom: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .Producers_title {
    width: 100%;
    display: flex;
    padding: 0px 0px;
    margin: 0px 0px;
    padding-top: 6%;

    padding-bottom: 10%;
    line-height: 5vh;
    font-size: 3.3rem;
    justify-content: center;
    align-content: center;
    display: none;
  }

  .Producers_body {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .prod_box {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    padding: 0;
    width: 100%;
    height: auto;
    margin-top: 0rem;
    border-radius: 15px;
  }
  .prod_box h3 {
    font-size: 1.8rem;
    line-height: 2.3rem;
  }

  .prod_box h6 {
    font-size: 1.3rem;
    margin: 0.1rem 0rem;
    line-height: 1.3rem;
  }

  .prod_box p,
  .prod_box a {
    display: none;
    /* line-height: 1.3rem;
    font-size: 0.9rem; */
  }
  .prod_iphone {
    width: 80vw;
    height: auto;
  }
  .prod_portal_btn {
    width: 70%;
    height: 3rem;
    margin-top: 5px;
    /* padding-right: 31px; */
  }

  .prod_portal_circle_ctn {
    position: relative;
    padding-left: 5px;
  }
  .MOBILE_prod_btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
