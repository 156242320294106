.toggle_btn {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 24px;
  width: 35px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0px;
  box-sizing: border-box;
  align-items: center;
}

.toggle_btn:focus {
  outline: none;
}
.toggle_btn_line {
  width: 30px;
  height: 2px;
  background: white;
}
