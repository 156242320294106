.MusicPlayer {
  /* color: white;
  background: url("../../assets/img/2cupshorty_beats1.png");
  width: 100%;
  height: 100%; */
}

.MusicPlayer_body a {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border: none;
  cursor: pointer;
  background: linear-gradient(113.75deg, #651c96 0%, #7721b0 100%);
  font-family: "Raleway";
  font-size: 16px;
  padding: 1rem 0;
  margin-top: 1.5%;
  border-radius: 25px;
  width: 20%;
}

.MusicPlayer_body {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.MusicPlayer_record_container {
  margin: auto auto;
  display: inline-block;
  overflow: hidden;
  width: 800px;
  height: 600px;
}
.pulse {
  animation: scales 2s linear infinite;
}
.MusicPlayer_record {
  position: relative;
  margin: 19px auto;
  width: 500px;
  height: 500px;
  border-radius: 50%;
  background: linear-gradient(30deg, transparent 40%, rgba(0, 0, 0, 0.85) 40%)
      no-repeat 100% 0,
    linear-gradient(60deg, rgba(0, 0, 0, 0.85) 60%, transparent 60%) no-repeat 0
      100%,
    repeating-radial-gradient(#2a2928, #2a2928 4px, #ada9a0 5px, #2a2928 6px);
  background-size: 50% 100%, 100% 50%, 100% 100%;

  animation: spin 4s linear infinite;
}

.MusicPlayer_record:after {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -51.47px;
  border: solid 1px #d9a388;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  box-shadow: 0 0 0 4px #da5b33, inset 0 0 0 27px #da5b33;
  background: #da5b33;
  content: "";
  animation: scales 2s linear infinite;
}
.MusicPlayer .Beats_info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.MusicPlayer .Beats_info h3 {
  color: white;
  font-family: "Raleway";
  margin: 2.5% 0;
}
.MusicPlayer .album_ctn img {
  border-radius: 50%;
  width: 80vw;
  max-width: 250px;
  height: auto;
  border-radius: 50%;
}

@media (max-width: 1022px) {
  .MusicPlayer .Beats_info h3 {
    margin: 2.5% 0;
    font-size: 1rem;
  }
  .MusicPlayer_body a {
    font-size: 16px;
    padding: 2.5% 0;
    margin-top: 5%;
    width: 50%;
  }
}

audio {
  margin: 0;
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes scales {
  0% {
    box-shadow: 0 0 0 0 rgba(85, 85, 85, 0.7), 0 0 0 0 rgba(82, 82, 82, 0.7);
  }
  40% {
    box-shadow: 0 0 0 50px rgba(87, 87, 87, 0), 0 0 0 0 rgba(82, 82, 82, 0.7);
  }
  80% {
    box-shadow: 0 0 0 50px rgba(85, 85, 85, 0), 0 0 0 30px rgba(85, 85, 85, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(82, 82, 82, 0), 0 0 0 30px rgba(80, 80, 80, 0);
  }
}
