.sidebar {
  height: 100%;
  background: linear-gradient(to right, rgb(41, 3, 102), rgba(49, 1, 126));
  box-shadow: 2px 0 3px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  width: 75%;

  max-width: 400px;
  z-index: 500;
  letter-spacing: 0.09rem;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}

.sidebar_open {
  transform: translateX(0);
}

.sidebar_close {
  display: block;
  background: transparent;
  border: none;
  position: absolute;
  width: 32px;
  height: 32px;
  opacity: 1;
  cursor: pointer;
}

.sidebar_close:hover {
  opacity: 1;
  color: white;
}
.sidebar_close:before,
.sidebar_close:after {
  position: absolute;
  content: " ";
  height: 33px;
  width: 2px;
  background-color: white;
}
.sidebar_close:before {
  transform: rotate(45deg);
}
.sidebar_close:after {
  transform: rotate(-45deg);
}
button:focus {
  outline: 0;
}

.side_menu {
  list-style: none;
  display: flex;
  flex-direction: column;
  font-size: 1.8rem;
  justify-content: center;
}

.side_menu li {
  color: white;
  font-family: "Bungee-Regular";
  font-size: 1.8rem;
  text-decoration: none;
  line-height: 4rem;
  margin: 0.2rem 0;
}

.side_menu li:hover,
.side_menu li:active {
  color: lightslategray;
}

@media (min-width: 1022px) {
  .sidebar {
    display: none;
  }
  .sidebar.open {
    display: none;
  }
}
