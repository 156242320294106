.Home {
  background: url("../../assets/img/beatstyle-home-bg-blur.png");
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 8vh;
}

.Home_content {
  display: grid;
  grid-template-columns: 1.2fr 1fr;
  margin-top: 10vh;
  justify-content: center;
}
.Home_images {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Home_images a {
  margin-top: 5%;
}
.Home_record_container {
  /* width: 100%;
  display: grid;
  grid-template-areas: "header";
  justify-items: center; */
  position: relative;
  /* margin: auto; */
  display: flex;
  justify-content: center;
}

.Home_record {
  position: relative;
  /* animation: spin 4s linear infinite; */
  /* text-align: center;
  grid-area: header; */
  /* place-self: center; */
}

.Home_record img {
  width: 26.25vw;
  min-width: 300px;
  height: auto;
  animation: spin 4s linear infinite;
}

.Home_iphone {
  /* position: relative; */
  position: absolute;
  top: -3%;
  /* margin: auto; */
  z-index: 1;
  /* text-align: center;
  grid-area: header;
  place-self: center; */
}

.Home_iphone img {
  width: 14vw;
  min-width: 160px;
  height: auto;
}

.Home_text {
  margin: 0;
  -ms-transform: translateY(10%);
  transform: translateY(10%);
}

.Home_text h1 {
  font-family: "Bungee-Regular";
  color: white;
  margin: 0;
  line-height: 3rem;
  font-size: 3rem;
}

.Home_text h3 {
  color: white;
  font-family: "Raleway";
  font-size: 23px;
}

.Home_btn_ctn {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  float: left;
  box-sizing: border-box;
}

.iphone_btn {
  text-decoration: none;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;
  border: none;
  cursor: pointer;
  background: linear-gradient(113.75deg, #4b21b0 0%, #1f006a 100%);
  font-family: "Raleway";
  font-size: 18px;
  padding-right: 15px;
  border-radius: 15px;
  width: 300px;
  height: 56px;
  margin: 10px;
}

.Home a:hover,
.Home button:hover {
  opacity: 0.5;
}

.android_btn {
  text-decoration: none;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #ffffff;
  border: none;
  cursor: pointer;
  background: linear-gradient(100.69deg, #0d7b18 0%, #074a0d 100%);
  font-family: "Raleway";
  font-size: 18px;
  padding-right: 15px;
  border-radius: 15px;
  width: 300px;
  height: 56px;
  margin: 10px;
}

.ios_icon {
  padding-left: 2%;
}
.and_icon {
  padding-left: 3.3%;
}

.txt_link {
  align-self: center;
  display: none;
  align-items: center;
  justify-content: center;
  color: #545454;
  border: none;
  cursor: text;
  font-family: "Raleway";
  font-size: 18px;
  border-radius: 15px;
  padding: 2%;
  /* width: 312px;
  height: 56px; */
  margin: 10px;
  width: 300px;
  height: 43px;
  background-color: white;
  letter-spacing: 2.08333px;
}

.txt_link input[type="text"] {
  border: none;
  border-bottom: 1px solid #545454;
  width: 50%;
  margin: 0px 5px;
  margin-right: 19px;
  font-family: "Raleway";
  text-align: center;
}

.txt_link input[type="text"]:focus {
  border: none;
  border-bottom: 1px solid #545454;
}

.send_btn {
  cursor: pointer;
  border-radius: 15px;
  border: none;
  width: 30%;
  height: 35px;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(96.61deg, #4b21b0 2.05%, #1f006a 96.83%);
  font-family: "Raleway";
  color: white;
  padding: 0px;
  font-size: 1rem;
}
.MOBILE_Home_text {
  display: none;
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@media (min-width: 1023px) {
  .Home {
    padding-top: 2rem;
  }
}

@media (max-width: 1022px) {
  .txt_link {
    display: none;
  }
  .MOBILE_Home_text h3 {
    display: none;
  }
  .Home {
    padding-bottom: 0;
    height: 100vh;
  }
  .Home_content {
    margin-top: 0;
    /* display: grid;
    grid-template-columns: none;
    grid-template-rows: 1fr 1fr; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: hidden;
  }
  .Home_record_container {
    height: 100%;
  }

  .Home_iphone {
    padding-top: 2.5rem;
    top: 0;
    position: relative;
    z-index: 1;
  }
  .Home_iphone img {
    width: 65vw;
    height: auto;
  }

  .Home_record {
    display: none;
  }

  .Home_text {
    display: none;
    margin: 25px;
    margin-top: 0px;
    justify-content: center;
  }

  .Home_text h1 {
    line-height: 2rem;
    font-size: 2rem;
    text-align: center;
  }

  .Home_text h3,
  .MOBILE_Home_text h3 {
    font-size: 16px;
    text-align: center;
    margin: 0.5rem;
  }
  .Home_btn_ctn {
    width: 100%;
    align-self: center;
    justify-content: center;
  }
  .MOBILE_Home_text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: "Bungee-Regular";
    color: white;
  }
}

@media (min-width: 768px) and (max-width: 900px) {
  .Home_iphone img {
    width: 350px;
    height: auto;
  }
}

@media (max-height: 755px) and (max-width: 1022px) {
  .Home_iphone {
    padding-top: 1rem;
  }
  .Home_iphone img {
    width: 250px;
    height: auto;
  }
}

@media (max-width: 376px) and (max-height: 700px) {
  .Home_iphone img {
    width: 60vw;
    height: auto;
  }
}
