.Footnote {
  background: #2e2e2e;
  padding: 2rem 6rem;
  font-family: "Raleway";
  display: flex;
}

.Footnote p strong {
  font-size: larger;
}

.Footnote_Contact {
  display: flex;
  flex-direction: column;
  width: 40%;
  align-self: flex-start;
}

.Footnote p {
  color: grey;
}

.txt_link {
  display: none;
  border: none;
  cursor: text;
  background: white;
  border-radius: 15px;
  padding: 10px;
  font-size: 18px;
  width: 300px;
  letter-spacing: 2.08333px;
  margin-bottom: 2%;
}

.txt_link input[type="text"]:focus {
  border: none;
  border-bottom: 1px solid #545454;
}

.send_btn {
  cursor: pointer;
  border-radius: 15px;
  border: none;
  width: 30%;
  height: 35px;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(96.61deg, #4b21b0 2.05%, #1f006a 96.83%);
  font-family: "Raleway";
  color: white;
  padding: 0px;
  font-size: 1rem;
}

.Footnote_Socials {
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2%;
}

.Footnote_Socials a {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Footnote_Socials a:hover {
  opacity: 0.5;
}

.Footnote_Socials img {
  width: 70%;
  height: auto;
}

.Footnote_Other {
  text-align: right;
  margin-left: 30%;
}

.Footnote_Menu {
  text-align: right;
  margin-left: 10%;
}

.Footnote_Menu a:hover,
.Footnote_Other a:hover {
  opacity: 0.5;
}

.Footnote ul {
  list-style: none;
  padding-left: 0;
}

.Footnote li,
.Footnote_Menu li {
  margin-bottom: 1.5rem;
  cursor: pointer;
}

.Footnote a,
.Footnote li {
  text-decoration: none;
  color: grey;
}

.MOBILE_LLC {
  display: none;
}

@media (max-width: 1022px) {
  .Footnote {
    flex-direction: column;
    padding: 1rem 1rem;
    justify-content: center;
    align-items: center;
  }

  .Footnote_Contact {
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0;
  }
  .Footnote_Contact p:first-child {
    display: none;
  }

  .Footnote_Socials {
    width: 50%;
  }
  .Footnote_Other,
  .Footnote_Menu {
    width: 100%;
    text-align: center;
    margin-left: 0;
  }
  .LLC {
    display: none;
  }
  .MOBILE_LLC {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: large;
  }
  .txt_link {
    display: none;
  }
}
