.Features {
  background: url("../../assets/img/crowd-bg.png");
  /* background: linear-gradient(
    128deg,
    rgb(36, 36, 36) 5%,
    rgba(74, 74, 74, 1) 100%
  ); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding-bottom: 8vh;
}

.Features_title {
  color: white;
  font-family: "Bungee-Regular";
  padding: 2%;
  margin: 0;
  font-size: 6vw;
  display: flex;
  align-items: center;
  line-height: 8rem;
}

.Features_body {
  justify-content: center;
  font-family: "Raleway";
}

.Features_section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.box {
  width: 14vw;
  padding: 2%;
  border-radius: 10px;
  z-index: 10;
}

.box p {
  line-height: 1rem;
  margin: 0;
}

.bold {
  font-weight: bold;
}

.purp_box_1 {
  position: relative;
  z-index: 10;
  left: 3%;
  margin-top: 10%;
  align-self: flex-start;
  background: linear-gradient(
    88.44deg,
    #4b21b0 0.4%,
    rgba(31, 0, 106, 0.53) 120.08%
  );
  color: white;
  padding-right: 50px;
}

.iphone_1 {
  align-self: center;
  width: 20vw;
  min-width: 160px;
  height: auto;
  position: relative;
  z-index: 11;
}
.purp_box_2 {
  position: relative;
  z-index: 10;
  right: 3%;
  margin-top: 10%;
  background: linear-gradient(
    88.44deg,
    rgba(75, 33, 176, 0.5) 0.4%,
    #1f006a 120.08%
  );
  color: white;
  text-align: right;
  padding-left: 40px;
}
.white_box_1 {
  color: black;
  position: relative;
  z-index: 10;
  left: 3%;
  margin-top: 10%;
  background: linear-gradient(88.44deg, #d3d3d3 0.4%, rgba(92, 92, 92, 0.53));
  text-align: left;
  padding-right: 50px;
}

.white_box_2 {
  color: black;
  position: relative;
  z-index: 10;
  right: 3%;
  margin-top: 10%;
  align-self: flex-start;
  background: linear-gradient(
    88.44deg,
    #d3d3d3 0.4%,
    rgba(92, 92, 92, 0.53) 120.08%
  );
  text-align: right;
  padding-left: 40px;
}
.mobileText {
  display: none;
}

@media (max-width: 1022px) {
  .Features_title {
    padding-top: 6%;
    justify-content: center;
    align-content: center;
    padding-bottom: 10%;
    line-height: 4vh;
    font-size: 3.3rem;
  }
  .iphone_1 {
    width: 80vw;
    height: auto;
  }

  .box {
    width: 42vw;
    max-width: 200px;
    display: none;
  }
  .box p {
    line-height: 1rem;
    font-size: 0.79rem;
  }
  .purp_box_1,
  .white_box_1 {
    left: 3%;
    padding-right: 3%;
  }
  .purp_box_2,
  .white_box_2 {
    right: 3%;
    padding-left: 3%;
  }
  .Features_section {
    flex-direction: column;
  }
  .mobileText {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Raleway";
    color: white;
    font-size: 2rem;
    position: relative;
    top: -2vh;
    padding-bottom: 2%;
  }
}
