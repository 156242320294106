@font-face {
  font-family: "Avenir Next - Bold";
  font-style: normal;
  font-weight: 400;
  src: local("Avenir Next - Bold"),
    url(./assets/fonts/AvenirNextRegular.woff) format("woff");
  unicode-range: U+000-5FF; /* Latin glyphs */
}

@font-face {
  font-family: "Bungee-Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Bungee-Regular"), url(./assets/fonts/Bungee-Regular.woff);
  unicode-range: U+000-5FF;
}

@font-face {
  font-family: "Bungee-Inline";
  font-style: normal;
  font-weight: normal;
  src: local("Bungee-Inline"),
    url(./assets/fonts/bungee-inline-v5-latin-regular.woff) format("woff");
  unicode-range: U+000-5FF;
}
@font-face {
  font-family: "Raleway";
  font-style: normal;
  font-weight: normal;
  src: local("Raleway"), url(./assets/fonts/Raleway-Regular.woff);
  unicode-range: U+000-5FF;
}
html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
