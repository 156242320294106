.navbar {
  top: 0;
  left: 0;
  width: 100%;
  background: transparent;
  height: 70px;
  overflow: hidden;

  font-weight: 800;
  font-family: "Avenir Next - Bold" !important;
}

.navbar_head {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-around;
  font-family: "Bungee-Inline" !important;
}

.navbar_logo {
  display: flex;
  justify-content: center;
  align-items: center;

  color: white;
  text-decoration: none;
  font-size: 2.7rem;
  font-family: "Bungee-Inline";
}
.navbar_title {
}
/* .navbar_items {
  float: right;
} */

.navbar_items ul {
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  margin: 0 25px;
  justify-content: space-around;
}

.navbar_items li {
  padding: 0.5rem 0.6rem;
  border-radius: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  transition: 0.8s;
  font-family: "Avenir Next - Bold";
  white-space: nowrap;
  font-size: 0.8rem;
  letter-spacing: 0.09rem;
  color: white;
  cursor: pointer;
}

.navbar_items li:hover {
  background: linear-gradient(
    to right,
    rgb(80, 0, 209),
    rgba(80, 0, 209, 0.486)
  );
}

.navbar img {
  height: 70px;
  width: 70px;
}

.navbar_title {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0vw;
  font-size: 4rem;
}

@media (max-width: 1022px) {
  .navbar {
    padding-top: 1rem;
  }
  .navbar_items {
    display: none;
  }
  .navbar_toggle {
    margin-left: 1%;
  }
  .navbar_head {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .navbar_logo {
    align-self: center;
    width: 100%;
    justify-content: space-between;
  }
  .navbar a {
    width: 100%;
    align-self: center;
    font-size: 2rem;
    margin: 0;
  }
  .navbar_title {
    text-align: center;
    font-size: 2rem;
    align-self: center;
    margin-left: 3.5%;
  }

  .navbar_items ul {
    margin: 0;
  }
  .push {
    flex: 1;
  }
  .logo_left {
    display: inline;
    margin-right: 1%;
  }
  .logo_left img {
    width: 60px;
    height: auto;
  }
  .navbar_title img {
    display: none;
  }
}

@media (min-width: 1023px) {
  .navbar_toggle {
    display: none;
  }
  .logo_left {
    display: none;
  }
  .navbar_head {
    padding: 0rem 1rem;
  }
}
